import React from 'react';
import * as Styled from '../headerWithParagraph/headerWithParagraphStyles';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { MainContainer } from '../../styles';
import useContentfulAssets from '../../hooks/useContentfulAssets';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../utils';
import { superScriptChars } from '../../constants/superScript';

export default function HeaderWithTickLinkAndButton({ sectionData }) {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.HeaderH2>{children}</Styled.HeaderH2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Styled.HeaderH3>{children}</Styled.HeaderH3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Styled.HeadingH4>{children}</Styled.HeadingH4>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <Styled.UnorderedList>{children}</Styled.UnorderedList>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <Styled.ListItem>{children}</Styled.ListItem>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.ButtonStyle
        href={isExternalUrlHref(node?.data?.uri)? node?.data?.uri :addTrailingSlash(process.env.GATSBY_DOMAIN_URL + node?.data?.uri)}
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </Styled.ButtonStyle>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        return asset?.node?.file?.url?.includes('image') ? (
          <Styled.ImageStyle
            src={asset?.node?.file?.url}
            alt={asset?.node?.title}
          />
        ) : (
          <></>
        );
      },
    },
    renderText: (text) =>
      text
        ?.split('\n')
        .flatMap((text, i) => [
          i > 0 && <br key={i} />,
          <React.Fragment key={i}>
            {text
              ?.split('')
              ?.map((i) =>
                superScriptChars.includes(i) ? (
                  <Styled.SuperScriptStyle>{i}</Styled.SuperScriptStyle>
                ) : (
                  i
                )
              )}
          </React.Fragment>,
        ]),
  };
  return (
    <MainContainer>
      {sectionData?.header?.trim() && (
        <Styled.HeaderH2>{sectionData?.header}</Styled.HeaderH2>
      )}
      {sectionData?.subText?.trim() && (
        <Styled.HeaderH3>{sectionData?.subText}</Styled.HeaderH3>
      )}
      {sectionData?.contentDetails &&
        documentToReactComponents(
          JSON.parse(sectionData?.contentDetails?.raw),
          optionsMainStyle
        )}
    </MainContainer>
  );
}
